.search-input {
  padding: 8px 15px !important;
  border: 1px solid #c9c9c9; /* Blue border for editable fields */
  padding: 5px; /* Padding for better text visibility */
  border-radius: 10px;
  width: 100% !important;
}
@media (max-width: 768px) {
  .search-input {
    padding: 5px;
    font-size: 0.9rem;
  }
}
