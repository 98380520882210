* {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media (max-width: 768px) {
  body {
    font-size: 0.5rem;
  }
}
@media (max-width: 443px) {
  body {
    font-size: 0.1rem;
  }
}
